import { Link } from 'react-router-dom';

import InfoMessage from '../../../components/shared/InfoMessage';
// import StatusIndicator from '../../../components/shared/StatusIndicator';
import { EDITING_TEXT } from '../../../constants';
import Button from '../../../components/shared/Button';
import { formatDateWithPoints } from '../../../utils';
import Icon from '../../../components/shared/Icon';
import { useModal } from '../../../context/modal-context';
import OrganizationPreview from './OrganizationPreview';

const OrganizationsList = ({ organizations, searchValue, onClick }: any) => {

	const {openModal} = useModal()

	const handleOpenModal = (e:any, organization: any) => {
		e.stopPropagation()
		openModal(<OrganizationPreview organization={organization} />, "Organizacija");
	}

	return (
		<ul className='flex flex-col gap-3'>
			{organizations?.length === 0 && searchValue !== '' ? (
				<li>
					<InfoMessage
						icon='info'
						message='Ne postoje rezultati za zadatu pretragu.'
					/>
				</li>
			) : (
				organizations?.map((organization: any) => {
					const formatedDate = formatDateWithPoints(
						organization?.date_edited?.date ||
							organization?.date_created?.date
					);
					return (
						<li
						onClick={(e:any) =>handleOpenModal(e, organization)}
							key={organization.id}
							className='pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer'
							/* onClick={() => onUpdate(region)} */
						>
							<div className='flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white'>
								<div className='flex gap-2 items-center'>
									{/* <>
										{organization.status === 'active' ||
										1 ? (
											<StatusIndicator
												label={'Aktivna'}
												active
											/>
										) : (
											<StatusIndicator
												label={'Neaktivna'}
											/>
										)}
									</> */}
									<div className='pks-layout-col'>
										<p className='font-bold'>
											{organization.name}
										</p>
										<span className='text-secondary-400'>
											{formatedDate}
										</span>
									</div>
								</div>
								<div className='inline-flex flex-wrap items-center gap-4'>
								<Link
										to={`${organization.id}`}
										className='transform scale-125 p-2'
										onClick={(e) => e.stopPropagation()}
										>
										<Icon
											name='pencil'
											aria-hidden='true'
											className='hover:text-primary-300'
											tabIndex={-1}
										/>
									</Link>
									<button /* onClick={() => onDelete(member.id)} */
									>
										<Icon
											name='trashDisabled'
											aria-hidden='true'
											className='text-white'
											tabIndex={-1}
										/>
									</button>
								</div>
							</div>
						</li>
					);
				})
			)}
		</ul>
	);
};

export default OrganizationsList;
