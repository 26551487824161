const ForumMemberPreview = ({ forumMember }: any) => {
  const forumMemberItemClassName = 'h3 font-normal flex flex-col gap-1 ';

  return (
    <ul className="space-y-3">
      <li className={forumMemberItemClassName}>
        <b>Institucija:</b> {forumMember?.organization?.name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Ime:</b> {forumMember.first_name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Prezime:</b> {forumMember.last_name}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Email:</b> {forumMember.email}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Uloga:</b>
        <div className="flex flex-wrap gap-2">
          {forumMember.roles.map((role: any, i: number) => (
            <p key={i} className="bg-secondary-100 w-fit py-1 px-2 rounded-lg">
              {role.name}
            </p>
          ))}
        </div>
      </li>
      <li className={forumMemberItemClassName}>
        <b>Telefon:</b> {forumMember.phone}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Mobilni telefon:</b> {forumMember.mobile_phone}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Faks:</b> {forumMember.fax}
      </li>
      <li className={forumMemberItemClassName}>
        <b>Funkcija:</b> {forumMember.organization_function}
      </li>
    </ul>
  );
};

export default ForumMemberPreview;
