import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import News from '../modules/news/UI/index';

import { ViewEnum } from '../types';

const EducationNewsPage = () => {
	const pageTitle = 'Vesti i korisne informacije';
	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout title={pageTitle}>
				<News view={ViewEnum.Grid} />
			</PageLayout>
		</>
	);
};

export default EducationNewsPage;
