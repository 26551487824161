export function getBaseUrl() {
	return process.env.REACT_APP_API_URL;
}

export function getPageTitle(title: string) {
	return `${title} | PKS`;
}

export function testRegex(regex: RegExp, input: string): boolean {
	return regex.test(input);
}

export function debounce<T extends (...args: any[]) => void>(
	func: T,
	delay: number = 250
): (...args: Parameters<T>) => void {
	let timeoutId: ReturnType<typeof setTimeout> | null = null;

	return (...args: Parameters<T>) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
}
