import { useEffect, useState } from 'react';
import { Link, useNavigate, useRouteError } from 'react-router-dom';
import Meta from '../components/layout/Meta';

import MainNavigation from '../components/layout/MainNavigationNew';
import Sidebar from '../layouts/SidebarLayout';
import Button from '../components/shared/Button';

import SidebarLayoutWrapper from '../layouts/SidebarLayout/Wrapper';
import PreviewSidebar from '../layouts/SidebarLayout/PreviewSidebar';

import { HOME_PAGE_LINK, LOGIN_PAGE_LINK } from '../constants';
import { useAuth } from '../context/auth-context';

const ERROR_MESSAGES: { [key: number]: string } = {
	500: 'Interna greška servera.',
	404: 'Stranica nije pronađena.',
	403: 'Zabranjen pristup. Token nije validan.',
	401: 'Neovlašćen pristup. Molimo prijavite se.',
};

interface RouteError {
	response: any;
	status?: number;
	message?: string;
}

function ErrorPage() {
	const error = useRouteError() as RouteError;
	const navigate = useNavigate(); // useNavigate hook to redirect
	const { isAuth, updateAuthState } = useAuth();
	// State to determine if redirection to login should occur
	const [shouldRedirect, setShouldRedirect] = useState<boolean | undefined>(
		isAuth ? false : undefined
	);

	useEffect(() => {
		if (isAuth) return;
		updateAuthState();
	}, [isAuth, updateAuthState]);

	// Redirect to login if unauthenticated
	useEffect(() => {
		if (!isAuth) {
			setShouldRedirect(true);
			navigate('/login', { replace: true });
		}
	}, [isAuth, navigate]);

	// Until auth user has been confirmed don't return any content since unauthorized user should be redirected to login page
	if ((shouldRedirect === undefined || shouldRedirect === true) && !isAuth) {
		return null;
	}

	const status = error?.response?.status || error.status || 503;
	const message =
		error.message ||
		(status && ERROR_MESSAGES?.[status]) ||
		'Došlo je do greške. Molimo pokušajte ponovo kasnije.';

	return (
		<>
			<Meta title='Error' />
			<>
				{isAuth && <MainNavigation />}
				<div className='pks-container flex flex-col gap-8 pt-28 pb-8 md:pt-32 md:pb-12 lg:flex-row lg:pt-40 lg:pb-40 lg:min-h-screen'>
					<main className='lg:flex-1'>
						<div className='pks-layout-col-xl pks-container flex flex-col gap-8 pt-8 pb-8 md:pt-12 md:pb-12 lg:pt-40 lg:pb-40'>
							<div className='pks-layout-col max-w-xs'>
								<h1 className='text-8xl font-bold'>{status}</h1>
								<h2 className='text-2xl font-bold'>
									{message}
								</h2>
							</div>
							<Link
								to={isAuth ? '/organizacije' : LOGIN_PAGE_LINK}>
								<Button>
									{isAuth ? 'Početna stranica' : 'Login'}
								</Button>
							</Link>
						</div>
					</main>
					{isAuth &&
						(status === 404 ? (
							<SidebarLayoutWrapper>
								<PreviewSidebar />
							</SidebarLayoutWrapper>
						) : (
							<Sidebar />
						))}
				</div>
			</>
		</>
	);
}

export default ErrorPage;
