import { EMAIL_REGEX, PASSWORD_REGEX } from './regex';

const REQUIRED_TEXT = "Vrednost je obavezna i ne može biti prazna.";

export const REQUIRED_VALIDATION_RULE = {
	required: REQUIRED_TEXT,
};

export const NUMBERS_VALIDATION_RULES = {
	pattern: {
		value: /^[0-9]$/,
		message: 'Enter numbers',
	},
};

export const LETTERS_VALIDATION_RULES = {
	pattern: {
		value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
		message: 'Enter letters, dashes, and spaces.',
	},
};

export const LETTERS_REQUIRED_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
		message: 'Enter only letters, dashes, or spaces.',
	},
};

export const EMAIL_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: EMAIL_REGEX,
		message: 'E-mail adresa nije validna. example@email.com',
	},
};

export const PASSWORD_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	minLength: {
		value: 12,
		message: 'Lozinka mora imati najmanje 12 karaktera',
	},
	maxLength: {
		value: 50,
		message: 'Lozinka ne može biti duža od 50 karaktera',
	},
	pattern: {
		value: PASSWORD_REGEX,
		message:
			'Lozinka mora sadržati barem jedno veliko slovo, jedno malo slovo, jedan broj i jedan specijalni znak',
	},
};

export const BIN_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{16}$/,
		message: 'Field must have exactly 16 digits',
	},
};

export const ACQUIRER_ID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	minLength: { value: 3, message: 'Field must have at least 3 characters' },
	maxLength: { value: 10, message: 'Field can have at most 10 characters' },
	pattern: { value: /^[A-Za-z]+$/, message: 'Only letters are allowed' },
};

export const MID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{8,15}$/,
		message: 'Field must have between 8 and 15 digits',
	},
};

export const TID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{8,10}$/,
		message: 'Field must have between 8 and 10 digits',
	},
};

export const POSTAL_CODE_VALIDATION_RULES = {
	pattern: {
		value: /^\d{5}(-\d{4})?$/,
		message: 'Enter a valid postal code.',
	},
};

export const DOMAIN_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^(https?:\/\/)?([a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/,
		message: 'Enter a valid domain.',
	},
};

export const PHONE_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^(\+?\d{8,15}|(0|00)?\d{8,15})$/,
		message: 'Enter a valid phone number',
	},
};

export const MOBILE_PHONE_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^\+?\d{8,15}$/,
		message: 'Enter a valid mobile phone number',
	},
};

export const FAX_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9+()-]{7,20}$/,
		message: 'Enter a valid fax number.',
	},
};
