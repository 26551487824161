import http from '../../../utils/http'

const API_KEY = "680cb578f86d45569b4b52cb42cc3531";

const URL_PARAMS = {
	LATEST_NEWS: {
		limit: 4,
	},
	NEWS: {
		page: 1,
		limit: 5,
		showLimitInUrl: false,
	},
};

class NewsApi {
	// Fetch latest news
	fetchLatestNews = async () => {
		return http.get(
			`https://newsapi.org/v2/top-headlines?country=us&pageSize=${URL_PARAMS.LATEST_NEWS.limit}&apiKey=${API_KEY}`
		);
	};

	// Fetch paginated news
	fetchNews = async (page: number, limit: number) => {
		return http.get(
			`https://newsapi.org/v2/top-headlines?country=us&page=${page}&pageSize=${limit}&apiKey=${API_KEY}`
		);
	};
}

export { URL_PARAMS};
export default NewsApi;