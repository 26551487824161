import {
	keepPreviousData,
	QueryKey,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import ForumMembersApi from './forumMembers.api';

import { ActionResponse } from '../../../types';

const QUERY_KEY = 'forum-members';

const MINUTE: number = 1000 * 60;
const api: ForumMembersApi = new ForumMembersApi();

class ForumMembersStore {
	/* public forumMembers: any[] = [];
	public forumMember: any = null; */

	preloadForumMembers = async (page?: number, searchTerm?: string) => {
		return await queryClient.fetchQuery(forumMembersOptions(page, searchTerm));
	};

	useGetForumMembers = (page?: number, searchTerm?: string) => {
		return useQuery(forumMembersOptions(page, searchTerm));
	};

	getForumMember = async (id: number): Promise<ActionResponse<any>> => {
		try {
			const res = await api.getForumMember(id);
			const resData = res.data;
			return {
				success: true,
				message: resData?.message || 'Organization successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	preloadForumMember = this.getForumMember;

	addForumMember = async (payload: any): Promise<ActionResponse<any>> => {
		try {
			const res = await api.addForumMember(payload);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
			return {
				success: true,
				message: resData?.message || 'Korisnik je uspešno dodat!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	updateForumMember = async (id: number, payload: any) => {
		try {
			const res = await api.updateForumMember(id, payload);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
			return {
				success: true,
				message: resData?.message || 'User successfully edited!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	setForumMemberRoles = async (id: number, data: any) => {
		try {
			const res = await api.setForumMemberRoles(id, data);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
			return {
				success: true,
				message: resData?.message || 'Roles successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	getForumMemberRole = async (id: number) => {
		try {
			const res = await api.getForumMemberRole(id)
			const resData = res.data;
			return {
				success: true,
				message: resData?.message || "Roles successfully fetched",
				data: resData
			}
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	}
}

export default ForumMembersStore;

/**
 * Returns query options for fetching / prefetching forum memberss.
 */
export const forumMembersOptions = (
	page?: number,
	searchTerm?: string
): UseQueryOptions<any, Error> => ({
	queryKey: [QUERY_KEY, { page, searchTerm }] as QueryKey,
	queryFn: async (): Promise<any> => {
		const response: AxiosResponse<any> = await api.getForumMembers(page, searchTerm);
		return response.data;
	},
	placeholderData: keepPreviousData,
});
