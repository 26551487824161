import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
	useCallback,
	useRef,
} from 'react';

import {
	LOGGED_OUT_INACTIVITY_MESSAGE,
	LOGGING_OUT_USER_MESSAGE,
	SESSION_EXPIRED_MESSAGE,
} from '../constants';
import { UserInterface } from '../types';
import {
	getAuthStateFromLocalStorage,
	getTokenDuration,
	showToast,
} from '../utils';

import AuthStore from '../modules/auth/services/auth.store';
import { toast } from 'react-toastify';
const authStore: AuthStore = new AuthStore();
const TOKEN_REFRESH_THRESHOLD = 300000; // 5 minutes in milliseconds
const IDLE_TIMEOUT_DURATION = TOKEN_REFRESH_THRESHOLD * 3; // 15 minutes in milliseconds

// Define the shape of the context value
interface AuthStateInterface {
	token?: string;
	refreshToken?: string;
	userId?: string;
	expiresAt?: string;
	user?: UserInterface;
	isAuth?: boolean | undefined;
}

interface AuthContextInterface extends AuthStateInterface {
	isLoggingOut: boolean;
	resetAuthState: () => void;
	updateAuthState: () => void;
	logoutAuthUser: () => void;
}

// Default initial state
const DEFAULT_AUTH_STATE: AuthStateInterface = {
	token: undefined,
	refreshToken: undefined,
	userId: undefined,
	expiresAt: undefined,
	user: undefined,
	isAuth: undefined,
};

// Initialize context with default values
const AuthContext = createContext<AuthContextInterface>({
	...DEFAULT_AUTH_STATE,
	isLoggingOut: false,
	resetAuthState: () => {
		console.warn('resetAuthState function not initialized');
	},
	updateAuthState: () => {
		console.warn('setAuthState function not initialized');
	},
	logoutAuthUser: () => {
		console.warn('logoutAuthUser function not initialized');
	},
});

interface AuthProviderProps {
	children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [authState, setAuthState] = useState<AuthStateInterface>(() => {
		const { token, refreshToken, expiresAt, userId, user } =
			getAuthStateFromLocalStorage();
		const isAuth = !!(token && refreshToken && expiresAt && userId && user);
		return { token, refreshToken, expiresAt, userId, user, isAuth };
	});
	const [isLoggingOut, setIsLoggingOut] = useState(false);

	const { isAuth } = authState;
	const isInvalid = !isAuth;
	const idleTimeoutId = useRef<NodeJS.Timeout | null>(null);

	// Reset Auth State
	const resetAuthState = useCallback(() => {
		setAuthState(DEFAULT_AUTH_STATE);
		localStorage.removeItem('auth');
	}, []);

	// Update Auth State
	const updateAuthState = useCallback(() => {
		const {
			token: lsToken,
			refreshToken: lsRefreshToken,
			userId: lsUserId,
			expiresAt: lsExpiration,
			user: lsUser,
		} = getAuthStateFromLocalStorage();

		if (lsToken && lsUserId && lsExpiration) {
			setAuthState((prevState) => ({
				...prevState,
				token: lsToken,
				refreshToken: lsRefreshToken,
				userId: lsUserId,
				user: lsUser,
				expiresAt: lsExpiration,
				isAuth: true,
			}));
		}
	}, []);

	// Logout User
	const logoutAuthUser = useCallback(async () => {
		setIsLoggingOut(true);
		toast.dismiss();
		await authStore.logoutUser();
		resetAuthState();
		setIsLoggingOut(false);
	}, [resetAuthState]);

	// Check for token expiration and refresh token if necessary
	useEffect(() => {
		if (!isAuth) return;

		const checkTokenExpiry = async () => {
			const tokenDuration = getTokenDuration();

			// If the token is expiring within the threshold, refresh it
			if (tokenDuration > 0 && tokenDuration <= TOKEN_REFRESH_THRESHOLD) {
				console.log('Token is about to expire, refreshing...');
				try {
					const response = await authStore.refreshToken();
					const { success, message } = response;

					if (success) {
						// Optionally update the auth state here
						console.log('Token successfully refreshed.');
					} else {
						console.error('Token refresh failed:', message);
						logoutAuthUser();
						showToast(SESSION_EXPIRED_MESSAGE, false);
					}
				} catch (error) {
					console.error('Error refreshing token:', error);
					logoutAuthUser();
					showToast(SESSION_EXPIRED_MESSAGE, false);
				}
			}
		};

		let timeoutId: string | number | NodeJS.Timeout | undefined;

		const scheduleCheck = () => {
			const tokenDuration = getTokenDuration();

			if (tokenDuration > 0) {
				// Schedule the check to run just before the token expires
				timeoutId = setTimeout(() => {
					checkTokenExpiry();
				}, tokenDuration - TOKEN_REFRESH_THRESHOLD);
			}
		};

		if (isAuth) {
			scheduleCheck();
		}

		// Clean up the timeout on unmount or when dependencies change
		return () => {
			clearTimeout(timeoutId);
		};
	}, [isAuth, logoutAuthUser]);

	const handleIdleTimeout = useCallback(() => {
		logoutAuthUser();
		showToast(LOGGED_OUT_INACTIVITY_MESSAGE, false);
	}, [logoutAuthUser]);

	// Idle timeout effect - if user inactive for 'IDLE_TIMEOUT_DURATION' (currently set to 15 minutes), log them out
	useEffect(() => {
		const resetIdleTimer = () => {
			if (idleTimeoutId.current) {
				clearTimeout(idleTimeoutId.current);
			}

			idleTimeoutId.current = setTimeout(
				handleIdleTimeout,
				IDLE_TIMEOUT_DURATION
			);
		};

		if (isAuth) {
			window.addEventListener('mousemove', resetIdleTimer);
			window.addEventListener('keydown', resetIdleTimer);
			window.addEventListener('scroll', resetIdleTimer);
		}

		return () => {
			if (idleTimeoutId.current) {
				clearTimeout(idleTimeoutId.current);
			}
			window.removeEventListener('mousemove', resetIdleTimer);
			window.removeEventListener('keydown', resetIdleTimer);
			window.removeEventListener('scroll', resetIdleTimer);
		};
	}, [isAuth, handleIdleTimeout]);

	// Handle storage changes
	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			// Ignore changes if `isInvalid` is true
			if (isInvalid) return;

			// Check if the change is related to the `auth` key
			if (event.storageArea === localStorage && event.key === 'auth') {
				if (event.newValue === null) {
					// The `auth` key was deleted
					logoutAuthUser();
					showToast(LOGGING_OUT_USER_MESSAGE, false);
				} else {
					try {
						// Parse the new auth state from localStorage
						const newAuthState = event.newValue
							? JSON.parse(event.newValue)
							: null;

						if (newAuthState) {
							// Log a warning for unauthorized changes
							console.warn(
								'Unauthorized change detected in localStorage auth key. Ignoring changes.'
							);

							// Optional: Revert unauthorized changes by resetting the `auth` key in localStorage
							localStorage.setItem(
								'auth',
								JSON.stringify(authState)
							);
						}
					} catch (error) {
						console.error(
							'Failed to parse updated auth data from localStorage',
							error
						);
					}
				}
			}
		};

		// Add event listener to handle changes
		window.addEventListener('storage', handleStorageChange);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [isInvalid, authState, resetAuthState, logoutAuthUser]);

	return (
		<AuthContext.Provider
			value={{
				...authState,
				isLoggingOut,
				updateAuthState,
				resetAuthState,
				logoutAuthUser,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
