import {
	Link,
	LoaderFunctionArgs,
	useLoaderData,
	useNavigate,
	useParams,
} from 'react-router-dom';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Icon from '../../../components/shared/Icon';
import FraudCaseSingleDetails from './FraudCaseSingleDetails';
import FraudCaseSingleComments from './FraudCaseSingleComments';

/* import { formatDateWithPoints } from '../../../utils'; */
import FraudCaseStore from '../services/fraudCases.store';
import Button from '../../../components/shared/Button';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingle = () => {
	const navigate = useNavigate()
	const { slug } = useParams();
	const fraudCaseDetailsData: any = useLoaderData();

	return (
		<>
			<div className="">
				<span
							onClick={() => navigate(-1)}
					className='flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
							<Button
								type='button'
								variant='back'
								tabIndex={-1}>
								Nazad na pregled 
							</Button>
				</span>
			</div>
		<div className='flex flex-col gap-8 lg:flex-row'>
			
			<div className='pks-layout-col-xl lg:flex-1 lg:pr-8 lg:border-r lg:border-transparent'>
			
				{/* Page Title With Actions */}
				
				<PageTitleWithActions title={fraudCaseDetailsData?.title}>
					<div className='flex gap-4'>
						{/* <Link
							to='/'
							target='_blank'
							className='focus:outline-none group'> */}
						<Icon
							name='doc'
							aria-hidden='true'
							/* className='hover:text-primary-300 group-focus:text-primary-300' */
							className='text-secondary-300'
							tabIndex={-1}
						/>
						<span className='sr-only'>Pregledaj dokument</span>
						{/* </Link> */}
						{/* <Link
							to='/'
							target='_blank'
							className='focus:outline-none group'> */}
						<Icon
							name='pdf'
							aria-hidden='true'
							className='text-secondary-300'
							tabIndex={-1}
						/>
						<span className='sr-only'>Pregledaj dokument</span>
						{/* </Link> */}
					</div>
				</PageTitleWithActions>

				{/* Fraud Case */}
				<>
					<FraudCaseSingleDetails
						id={slug}
						data={fraudCaseDetailsData}
					/>
				</>
			</div>
			<div className='lg:w-[358px] py-4 border-t border-t-secondary-400'>
				<div className='pks-layout-col-xl'>
					<FraudCaseSingleComments id={slug} />
				</div>
			
			</div>
			
			
			</div>
		</>
			
	);
};

export default FraudCaseSingle;

export async function loader({ params }: LoaderFunctionArgs) {
	const slug = Number(params.slug);
	const data = await fraudCaseStore.preloadFraudCase(slug);
	return data;
}
