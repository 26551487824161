import { Navigate } from 'react-router-dom';
import Meta from '../components/layout/Meta';

/* import PageLayout from '../layouts/PageLayout/';
import Statistics from '../modules/statistics/UI';
import FreudCasesPreview from '../modules/fraudCasesOld/UI/Preview'; */

function HomePage() {
	return (
		<>
			<Meta />
			{/* remove after home page layout finished */}
			<Navigate to='administration/' replace={true}></Navigate>
			{/* <PageLayout title='Statistika'>
				<Statistics />
				<FreudCasesPreview count={7} />
			</PageLayout> */}
		</>
	);
}

export default HomePage;
