import { LoaderFunctionArgs, useSearchParams } from 'react-router-dom';

import Pagination from '../../../components/shared/Pagination';
import ForumMembersList from './ForumMembersList';

import ForumMembersStore from '../services/forumMembers.store';
import { useState } from 'react';
import SearchInput from '../../../components/shared/SearchInput';
const forumMembersStore = new ForumMembersStore();

const ForumMembers = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	// Parse URL search parameters
	const page = Number(searchParams.get('page')) || 1;
	const [searchValue, setSearchValue] = useState(
		searchParams.get('search') || ''
	);

	searchParams.delete('search');


	const { data: forumMembersQueryData, isFetching } =
		forumMembersStore.useGetForumMembers(page, searchValue);
	
	const forumMembers = forumMembersQueryData?._embedded?.users || [];
	const currentPage = forumMembersQueryData?._embedded?._page || 1;
	const totalPages = forumMembersQueryData?._page_count || 1;


	/**
	 * Handles page changes in pagination.
	 * Updates the URL with the new page number.
	 *
	 * @param {number} newPage - The new page number to navigate to.
	 */
	const handlePageChange = (newPage: number) => {
		if (newPage.toString() !== currentPage) {
			searchParams.set('page', newPage.toString()); // Update the page parameter
			// Update the search parameters in the URL
			setSearchParams(searchParams);
		}
	};

	const handleSearch = (debouncedValue: string): void => {
		setSearchValue(debouncedValue);
		setSearchParams({ page: '1' });
	};

	return (
		<div className='pks-layout-col-md'>
			<SearchInput
				value={searchValue}
				onSearch={handleSearch}
				autofocus
				isFetching={isFetching}
			/>
			<ForumMembersList forumMembers={forumMembers} />
			<Pagination
				currentPage={page}
				totalPages={totalPages}
				onPageChange={handlePageChange}
			/>
		</div>
	);
};

export default ForumMembers;

/**
 * Loader function to prefetch forum members.
 */
export async function loader({ request }: LoaderFunctionArgs) {
	const { searchParams } = new URL(request.url);
	const page = Number(searchParams.get('page')) || 1;

	// Fetch data based on the page number
	await forumMembersStore.preloadForumMembers(page);
	return null;
}
