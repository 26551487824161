import { Link } from 'react-router-dom';

import Icon from '../../../components/shared/Icon';
import InfoMessage from '../../../components/shared/InfoMessage';
import { formatDateWithPoints } from '../../../utils';
import { useModal } from '../../../context/modal-context';
import ForumMemberPreview from './ForumMemberPreview';

const ForumMembersList = ({ forumMembers }: any) => {

	const {openModal} = useModal()

	const handleOpenModal = (e:any, forumMember: any) => {
		e.stopPropagation()
		openModal(<ForumMemberPreview forumMember={forumMember} />, "Član foruma");
	}

	return (
		<>
		<ul className='flex flex-col gap-3'>
			{forumMembers.length === 0 ? (
				<li>
					<InfoMessage
						icon='info'
						message='Ne postoje rezultati za zadatu pretragu.'
					/>
				</li>
			) : (
				forumMembers?.map((member: any) => {
					const formatedDate = formatDateWithPoints(
						member?.date_edited?.date || member?.date_created?.date
					);
					return (
						<li
							onClick={(e:any) =>handleOpenModal(e, member)}
							id={member.id}
							key={member.id}
							className='pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group'>
							<div className='flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white'>
								<div className='pks-layout-col'>
									<p>{member.name}</p>
									<span className='text-secondary-400'>
										{formatedDate}
									</span>
								</div>
								<div className='inline-flex items-center flex-wrap gap-4'>
									<Link
										to={`${member.id}`}
										className='transform scale-125 p-2'
										onClick={(e) => e.stopPropagation()}
										>
										<Icon
											name='pencil'
											aria-hidden='true'
											className='hover:text-primary-300'
											tabIndex={-1}
										/>
									</Link>
									<button /* onClick={() => onDelete(member.id)} */
									>
										<Icon
											name='trashDisabled'
											aria-hidden='true'
											className='text-white'
											tabIndex={-1}
										/>
									</button>
								</div>
							</div>
						</li>
					);
				})
			)}
			</ul>
		</>
			
	);
};

export default ForumMembersList;
