import { useState, useEffect, useRef } from 'react';

interface DropDownItemInterface {
  id: number;
  label: string;
  selected?: boolean;
}

interface UseDropdownOptions {
  fetchData: (page: number) => any; 
  extractItems: (data: any) => DropDownItemInterface[]; 
  initialPage?: number;
  dependencies?: any[];
}

export const usePaginatedDropdown = ({
  fetchData,
  extractItems,
  initialPage = 1,
  dependencies = [],
}: UseDropdownOptions) => {
  const [availableItems, setAvailableItems] = useState<DropDownItemInterface[]>([]);
  const [finalItems, setFinalItems] = useState<DropDownItemInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const isLoadingRef = useRef<boolean>(true);

 
  const { data, isLoading, isFetching } = fetchData(currentPage);

  useEffect(() => {
    if (!data || isLoading || isFetching) return;

    const { _embedded = {}, _page = 1, _page_count = 1 } = data;

    const newItems = extractItems(_embedded);

    
    setAvailableItems((prevItems) =>
      prevItems.concat(
        newItems.filter((item) => !prevItems.some((prevItem) => prevItem.id === item.id))
      )
    );

    if (_page < _page_count) {
      setCurrentPage(_page + 1);
    } else {
      isLoadingRef.current = false;
    }
  }, [data, isLoading, isFetching, ...dependencies]);

  
  useEffect(() => {
    if (isLoadingRef.current === true) return;
    setFinalItems(availableItems);
  }, [availableItems]);

  return {
    finalItems,
    isLoadingRef,
  };
};
