import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Form, useNavigate, useSearchParams } from "react-router-dom"
import { LOGIN_PAGE_LINK, PASSWORD_VALIDATION_RULES, SEND, SUBMITTING_TEXT } from "../../../constants";
import Input from "../../../components/shared/Input";
import Button from "../../../components/shared/Button";
import AuthStore from "../services/auth.store";
import { useEffect } from "react";
import { extractMultipleErrors, hasNestedObjects, showToast } from "../../../utils";

const authStore: AuthStore = new AuthStore();


const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
	const [searchParams] = useSearchParams()
		const methods = useForm({
        defaultValues: {
            token: '',
			newPassword: '',
            confirmPassword: '',
          },
    });
    
	useEffect(() => {
		const token = searchParams.get("token")
		if(token) setValue("token", token)
	}, [])

    const {
		handleSubmit,
		formState: { isSubmitting, isValid },
		setValue,
		watch,
		setError,
	} = methods;
	
	const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
		if (isValid) {
			const response = await authStore.resetPassword(data.token, data.newPassword, data.confirmPassword)
			const { success, message } = response;
			
			if (success) {
				const resData = response.data;
				if (!resData) {
					showToast(message, !success);
					return;
				}
				showToast(message, true);
				navigate(LOGIN_PAGE_LINK);
			} else {
				if (hasNestedObjects(message)) {
					// Set input fields errors
					const errorMessages = extractMultipleErrors(message);
					for (const key in errorMessages) {
						setError(key as 'newPassword' | 'confirmPassword', {
							type: 'backend',
							message: errorMessages[key],
						});
					}
				} else {
					// Show toast error
					showToast(message, success, true);
				}
			}
		}
		
		
    }
    
    return (
        <>
          <FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-xl w-full'
					onSubmit={handleSubmit(onSubmit)}>
					<div className='pks-layout-col'>
						<Input
							type='password'
                            id='newPassword'
							name='newPassword'
                            label="Unesi novu lozinku"
							placeholder='Unesi novu lozinku'
							autoFocus
							autoComplete='off'
							validationRules={PASSWORD_VALIDATION_RULES}							
                        />
                        <Input
							type='password'
                            id='confirmPassword'
							name='confirmPassword'
                            label="Potvrdi novu lozinku"
							placeholder='Potvrdi novu lozinku'
							autoFocus
							autoComplete='off'
							validationRules={{
								...PASSWORD_VALIDATION_RULES,
								validate: (value: string): any => {
									if (watch('newPassword') !== value) {
										return "Nova lozinka i potvrda lozinke nisu iste. Molimo vas da unesete iste lozinke.";
									}
								},
							}}
							

						/>
					</div>
					{/* Submit Button */}
					<Button type='submit' wide disabled={isSubmitting}>
						{isSubmitting ? SUBMITTING_TEXT : SEND}
					</Button>
				</Form>
			</FormProvider>
        </>
    )
}

export default ResetPassword
