import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import OrganizationForm from '../../modules/organizations/UI/OrganizationForm';
import { useParams } from 'react-router-dom';

function OrganizationFormPage() {
	const {slug} = useParams()
	const pageTitle: string = slug ? 'Izmena organizacije' : 'Unos organizacije';

	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout className='max-w-[500px]' title={pageTitle}>
				<OrganizationForm />
			</PageLayout>
		</>
	);
}

export default OrganizationFormPage;
