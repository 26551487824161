import { useNavigate, useSearchParams } from 'react-router-dom';

import NewsList from './List';
import SeeMoreLink from '../../../components/shared/SeeMoreLink';
import Pagination from '../../../components/shared/Pagination';

// import { useFetchNews } from '../services/index.queries';
import { URL_PARAMS } from '../services/news.api';
import { ViewType, ViewEnum } from '../../../types';
import NewsStore from '../services/news.store';

const NEWS = URL_PARAMS.NEWS;


/**
 * NewsPreviewProps Interface
 *
 * Defines the props for the News component.
 * @interface
 * @property {string} [title] - The title of the news section.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsPreviewProps {
	title?: string;
	view?: ViewType;
}

/**
 * News Component
 *
 * This component fetches and displays a list of news articles with pagination.
 * It supports different view types (compact, detailed, grid) and provides navigation controls.
 * Loaded via a 'loader' in the router configuration.
 *
 * @param {NewsPreviewProps} props - The props object for the component.
 * @returns {React.JSX.Element | null} The rendered News component or null if data is not available.
 */

const newsStore = new NewsStore()

const News: React.FC<NewsPreviewProps> = ({
	title,
	view = ViewEnum.Compact,
}: NewsPreviewProps): React.JSX.Element | null => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	// Extract query parameters for pagination
	const page = Number(searchParams.get('page')) || NEWS.page;
	const limit = Number(searchParams.get('limit')) || NEWS.limit;

	// Fetch news data using the custom useFetchNews hook
    const { data, isLoading, isError, error } = newsStore.useFetchNews(page, limit);

 	 if (!data) return null;    
    const { articles, totalResults } = data;
	 const totalPages = totalResults && Math.ceil(totalResults / limit);
    console.log(totalResults, limit)
    console.log(totalPages)
	// Handle page change for pagination
	 const handlePageChange = (newPage: number) => {
	 	navigate(`?page=${newPage}${`&limit=${limit}`}`);
	 };

	return (
		<aside className='pks-layout-col-md'>
			{title && <h2>{title}</h2>}
			<div className='pks-layout-col-md'>
				<NewsList news={articles} view={view} />
				{/* <SeeMoreLink url='/' label='Sve vesti' /> */}
				<Pagination
					currentPage={page}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</div>
		</aside>
	);
};

export default News;