export const getAuthStateFromLocalStorage = () => {
	const auth = localStorage.getItem('auth');
	if (auth) {
		try {
			const parsedAuth = JSON.parse(auth) as {
				token?: string;
				expiresAt?: string;
				refreshToken?: string;
				userId?: string;
				user: any;
			};
			return {
				token: parsedAuth.token || undefined,
				expiresAt: parsedAuth.expiresAt || undefined,
				refreshToken: parsedAuth.refreshToken || undefined,
				userId: parsedAuth.userId || undefined,
				user: parsedAuth.user || undefined,
			};
		} catch (error) {
			console.error('Failed to parse auth data from localStorage', error);
		}
	}
	return {
		token: undefined,
		expiresAt: undefined,
		refreshToken: undefined,
		userId: undefined,
		user: undefined,
	};
};

export function getAuthToken() {
	const { token } = getAuthStateFromLocalStorage() || {};

	if (!token) {
		return null;
	}

	const tokenDuration = getTokenDuration();

	if (tokenDuration < 0) {
		return 'EXPIRED';
	}

	return token;
}

export function getTokenDuration() {
	const authState = getAuthStateFromLocalStorage();

	const { expiresAt: storedExpirationDate } = authState || {};

	if (!storedExpirationDate) {
		// console.log('No stored expiration date found.');
		return 0;
	}

	// Convert from seconds to milliseconds if needed
	const expirationDateTimestamp = Number(storedExpirationDate) * 1000;

	if (isNaN(expirationDateTimestamp)) {
		console.error('Invalid expiration date:', storedExpirationDate);
		return 0;
	}

	// Create a Date object from the timestamp
	const expirationDate = new Date(expirationDateTimestamp);
	// console.log('Stored Expiration Date (Seconds):', storedExpirationDate);
	// console.log('Parsed Expiration Date (Date object):', expirationDate);

	const now = new Date();
	const duration = expirationDate.getTime() - now.getTime();
	return duration;
}
