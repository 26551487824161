import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import { useEffect } from 'react';

const useAuthRedirect = () => {
	const navigate = useNavigate();
	const { isAuth } = useAuth();

	// Effect to handle validation and redirection
	useEffect(() => {
		if (!isAuth) {
			navigate('/login', { replace: true });
			return;
		}
	}, [isAuth, navigate]);
};

export default useAuthRedirect;
