// src/layouts/AuthLayout.tsx
import MainNavigation from '../../components/layout/MainNavigationNew';
import AuthContentWithRedirect from './AuthContentWithRedirect';
import Footer from '../../components/layout/Footer';

import { useAuth } from '../../context/auth-context';
import { useEffect, useState } from 'react';
import PageLoader from '../PageLayout/Loader';

/**
 * AuthLayout Component
 *
 * This component handles the layout for authenticated users. It uses context and hooks to
 * manage authentication state and conditional rendering based on that state.
 *
 * @component
 * @returns {React.JSX.Element} - The JSX element representing the authenticated layout.
 */
const AuthLayout = (): React.JSX.Element => {
	// State to determine if redirection to login should occur
	const [shouldRedirect, setShouldRedirect] = useState(false);

	// Destructuring auth-related states and functions from the custom hook
	const { isAuth, user, updateAuthState } = useAuth();

	useEffect(() => {
		if (!isAuth) {
			updateAuthState();
			setShouldRedirect(true);
		} else {
			setShouldRedirect(false);
		}
	}, [isAuth, updateAuthState]);

	// Conditional rendering of content based on authentication state
	let content = <>{shouldRedirect && <AuthContentWithRedirect />}</>;

	// If the user is authenticated, render the main content and navigation
	if (isAuth) {
		content = (
			<div className='bg-secondary-50'>
				<MainNavigation userName={user?.name} />
				<div className='pks-container flex flex-col gap-8 pt-28 pb-8 md:pt-32 md:pb-12 lg:flex-row lg:pt-40 lg:pb-40 min-h-screen'>
					<AuthContentWithRedirect isAuth={isAuth} />
				</div>
				<Footer />
			</div>
		);
	}

	// Render content
	return (
		<>
			<PageLoader init />
			{content}
		</>
	);
};

export default AuthLayout;
