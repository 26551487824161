import PageLayout from '../layouts/PageLayout';

import dummyNewsLargeImg from '../assets/images/dummy/newsLarge.png';

const EducationNewsDetailsPage = () => {
	return (
		<PageLayout isCms>
			<>
				<h1 className='mb-4'>Naslov Vesti 1</h1>

				<div className='pks-layout-col-md'>
					<span className='text-secondary-400'>12.06.2024.</span>

					{/* Content 1 */}
					<div>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor incididunt ut labore et dolore
						magna aliqua. Ut enim ad minim veniam, quis nostrud
						exercitation ullamco laboris nisi ut aliquip ex ea
						commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu
						fugiat nulla pariatur.
					</div>

					{/* Image 1 */}
					<div>
						<img src={dummyNewsLargeImg} alt='' />
					</div>

					{/* Content  2 */}
					<div>
						<h3>Lorem ipsum dolor</h3>
						<div>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit
							</p>
							<ul>
								<li>
									Sed do eiusmod tempor incididunt ut labore
									et dolore magna aliqua
								</li>
								<li>
									Ut enim ad minim veniam, quis nostrud
									exercitation ullamco laboris nisi ut aliquip
									ex ea commodo consequat{' '}
								</li>
								<li>
									Duis aute irure dolor in reprehenderit in
									voluptate velit esse cillum dolore eu fugiat
									nulla pariatur
								</li>
							</ul>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation
								ullamco laboris nisi ut aliquip ex ea commodo
								consequat. Duis aute irure dolor in
								reprehenderit in voluptate velit esse cillum
								dolore eu fugiat nulla pariatur.
							</p>
						</div>
					</div>

					{/* Image 2 */}
					<div>
						<img src={dummyNewsLargeImg} alt='' loading='lazy' />
					</div>

					{/* Content 3 */}
					<div>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit
						<ol>
							<li>
								Sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua
							</li>
							<li>
								Sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua Ut enim ad minim veniam,
								quis nostrud exercitation ullamco laboris nisi
								ut aliquip ex ea commodo consequat
							</li>
							<li>
								Duis aute irure dolor in reprehenderit in
								voluptate velit esse cillum dolore eu fugiat
								nulla pariatur
							</li>
						</ol>
					</div>
				</div>
			</>
		</PageLayout>
	);
};

export default EducationNewsDetailsPage;
