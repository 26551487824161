import Button from '../../../components/shared/Button';
import { useLogout } from '../../../hooks/useLogout';

const LogoutButton = () => {
	const { handleLogout } = useLogout();

	return (
		<Button className='w-[150px]' onClick={handleLogout}>
			Izloguj se
		</Button>
	);
};

export default LogoutButton;
