import { useCallback, useEffect, useRef, useState } from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Pagination from '../../../components/shared/Pagination';
import Button from '../../../components/shared/Button';
import InfoMessage from '../../../components/shared/InfoMessage';
import Modal, { ModalHandleInterface } from '../../../components/shared/Modal';
import RolesList from './RolesList';
import RoleForm from './RoleForm';
import PermissionsList from './PermissionsList';

import { useInfiniteScrollSentinel } from '../../../hooks/useInfiniteScroll';
import { debounce, showToast } from '../../../utils';

import {
	InfinitePermissionsResponseDataInterface,
	RoleAddInterface,
} from '../services/role.types';
import RolesStore from '../services/roles.store';

const rolesStore: RolesStore = new RolesStore();

interface RolesModalInterface {
	role: RoleAddInterface | undefined;
	isOpen: boolean;
}

const INIT_FORM_OPTIONS: RolesModalInterface = {
	role: undefined,
	isOpen: false,
};

const Roles = () => {
	const [currentPage, setCurrentPage] = useState(1);

	/* Roles */
	const { data: rolesData } = rolesStore.useGetRoles(currentPage);
	const roles = rolesData?._embedded.roles;
	const totalPages = rolesData?._page_count || 1;
	const [selectedRole, setSelectedRole] = useState<number | undefined>(
		roles && roles.length > 0 ? roles[0].id : undefined
	);

	/* Permissions per role */
	const { data: permissionsPerRoleData } =
		rolesStore.useGetPermissionsPerRole(selectedRole);
	const [permissionsPerRole, setPermissionPerRole] = useState<
		Record<number, number[]>
	>({});
	const [optPermissionsPerRoleData, setOptPermissionPerRoleData] = useState<
		Record<number, number[]>
	>({});

	/* Permissions */
	const {
		data: permissionsData,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	}: UseInfiniteQueryResult<
		InfinitePermissionsResponseDataInterface,
		Error
	> = rolesStore.useGetPermissions(1);

	const permissions = permissionsData?.pages.flatMap(
		(page) => page?._embedded?.permissions || []
	);

	/* Infinite scroll management */
	const permissionsSentinelRef = useRef<HTMLDivElement>(null);

	/* Modal management */
	const dialogRef = useRef<ModalHandleInterface>(null);
	const [modalOptions, setModalOptions] =
		useState<RolesModalInterface>(INIT_FORM_OPTIONS);

	/**
	 * Effects
	 */

	// Infinite Scroll
	useInfiniteScrollSentinel({
		sentinelRef: permissionsSentinelRef,
		hasNextPage,
		loadMore: fetchNextPage,
		isLoading: isFetchingNextPage,
	});

	// Set initial selected role
	useEffect(() => {
		if (selectedRole) return;
		if (roles && roles.length > 0 && selectedRole === undefined) {
			setSelectedRole(roles[0].id);
		}
	}, [roles, selectedRole]);

	// Toggle Modal
	useEffect(() => {
		modalOptions.isOpen
			? dialogRef.current?.open()
			: dialogRef.current?.close();
	}, [modalOptions.isOpen]);

	// Initialize `permissionsPerRole` state when `permissionsPerRoleData` or `selectedRole` changes
	useEffect(() => {
		if (permissionsPerRoleData?.permissions) {
			setPermissionPerRole((prev) => ({
				...prev,
				[selectedRole as number]:
					permissionsPerRoleData.permissions.map(
						(permission: any) => permission.id
					),
			}));
		}
	}, [permissionsPerRoleData, selectedRole]);

	/**
	 *  Handlers
	 */

	// Update modal form options
	const handleUpdateFormOptions = (role?: RoleAddInterface | null): void => {
		setModalOptions({
			isOpen: true,
			role: role ?? undefined,
		});
	};

	// Handles modal close
	const handleCloseModal = useCallback(() => {
		setModalOptions(INIT_FORM_OPTIONS);
	}, []);

	// Handles page change
	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	// Handles selected role
	const handleSelectedRole = (id: number) => {
		setSelectedRole(id);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedUpdateRolePermissions = useCallback(
		debounce(
			async (
				role: number,
				permissions: number[],
				previousPermissions: number[]
			) => {
				// Perform the request to update the role permissions
				const { success, message } =
					await rolesStore.updateRolePermissions(role, permissions);

				setPermissionPerRole((prev) => ({
					...prev,
					[role]: permissions,
				}));

				if (!success) {
					setPermissionPerRole((prev) => ({
						...prev,
						[role]: previousPermissions,
					}));
					setOptPermissionPerRoleData((prev) => ({
						...prev,
						[role]: previousPermissions,
					}));
					showToast(message, success);
				}
			},
			250 // Adjust the debounce delay as needed
		),
		[]
	);

	// Handles permission changes for selected role
	const handleCheckedItemsChange = useCallback(
		async (updatedPermissions: number[]) => {
			toast.dismiss();

			if (!selectedRole) {
				showToast('Izaberite rolu.', false);
				return;
			}

			if (updatedPermissions.length === 0) {
				showToast(
					'Svaka rola treba imati barem jednu izabranu permisiju.',
					false
				);
				return;
			}

			// Maintain a backup of the previous state
			const previousPermissions = permissionsPerRole[selectedRole] || [];

			// Optimistically update the state
			setOptPermissionPerRoleData((prev) => ({
				...prev,
				[selectedRole as number]: updatedPermissions,
			}));

			// Call the debounced function
			debouncedUpdateRolePermissions(
				selectedRole,
				updatedPermissions,
				previousPermissions
			);
		},
		[selectedRole, permissionsPerRole, debouncedUpdateRolePermissions]
	);

	return (
		<>
			<div className='flex flex-col lg:flex-row gap-8'>
				{/* Roles */}
				<div className='flex-1 h-full'>
					<div className='pks-layout-col-md h-full'>
						<PageTitleWithActions title='Role'>
							<Button onClick={() => handleUpdateFormOptions()}>
								Dodaj rolu
							</Button>
						</PageTitleWithActions>
						<RolesList
							roles={roles}
							selected={selectedRole}
							onClick={handleSelectedRole}
							onUpdate={handleUpdateFormOptions}
						/>
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
				{/* Permissions */}
				<div className='flex-1'>
					<div className='pks-layout-col-md'>
						<div className='flex flex-col gap-4 lg:flex-row lg:items-center lg:flex-nowrap'>
							<h2 className='h1'>Permisije</h2>
							<p className='sm:min-h-[46px] text-sm flex flex-wrap items-center'>
								* Svaka rola treba imati barem jednu izabranu
								permisiju.
							</p>
							{/* <Button className='opacity-0'>Placeholder</Button> */}
						</div>
						{permissionsData ? (
							<div>
								{permissions && permissions.length > 0 && (
									<div className='pks-layout-col-md'>
										<PermissionsList
											permissions={permissions}
											permissionsPerRole={
												optPermissionsPerRoleData[
													selectedRole as number
												] ||
												permissionsPerRole[
													selectedRole as number
												] ||
												[]
											}
											onCheckedItemsChange={
												handleCheckedItemsChange
											}
										/>
										{isFetchingNextPage && (
											<InfoMessage
												icon='info'
												message='Loading Data ...'
											/>
										)}
										<div
											ref={permissionsSentinelRef}
											style={{ height: '1px' }}
										/>
									</div>
								)}
							</div>
						) : (
							<InfoMessage
								icon='info'
								message={
									!isFetching
										? 'Nema dostupnih dozvola'
										: 'Loading Data ...'
								}
							/>
						)}
					</div>
				</div>
			</div>
			{/* Modal for Add / Edit Role Form */}
			{modalOptions.isOpen && (
				<Modal ref={dialogRef} onClose={handleCloseModal}>
					<RoleForm
						role={modalOptions.role}
						onClose={handleCloseModal}
					/>
				</Modal>
			)}
		</>
	);
};

export default Roles;

export async function loader({ request }: LoaderFunctionArgs) {
	const { searchParams } = new URL(request.url);
	const page = Number(searchParams.get('page')) || 1;

	await rolesStore.preloadRoles(page);
	return null;
}
