import { useState, useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import useInputContext from '../../../hooks/useInputContext';

import Icon from '../Icon';

/** File Item */
const FILE_ITEM_CLASS_NAME = {
	item: 'flex gap-4 items-center',
	button: 'underline truncate',
	trashIcon: 'flex items-center',
};

interface FileItemInterface {
	file: File;
	index: number;
	onDelete: (index: number) => void;
}

const FileItem: React.FC<FileItemInterface> = ({ file, index, onDelete }) => (
	<li className={FILE_ITEM_CLASS_NAME.item} key={index}>
		<button
			type='button'
			className={FILE_ITEM_CLASS_NAME.button}
			onClick={() => window.open(URL.createObjectURL(file), '_blank')}>
			{file.name}
		</button>
		<button
			type='button'
			onClick={() => onDelete(index)}
			className={FILE_ITEM_CLASS_NAME.trashIcon}>
			<Icon name='trash' />
		</button>
	</li>
);

/** File Input */
type AllowedFileTypes =
	| 'application/pdf'
	| 'image/jpeg'
	| 'image/jpg'
	| 'image/png';

interface FileInputProps {
	id: string;
	name: string;
	label: string;
	multiple?: boolean;
	required?: boolean;
	allowedTypes?: AllowedFileTypes[];
	maxSizeMB?: number;
	showFiles?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
	id,
	name,
	label,
	allowedTypes = [],
	multiple = false,
	required,
	maxSizeMB,
	showFiles = true,
}) => {
	const { control, value: files, setValue, error } = useInputContext(name);
	const [sizeError, setSizeError] = useState<string | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	// Generate 'accept' attribute based on allowedTypes
	const acceptTypes =
		allowedTypes.length > 0 ? allowedTypes.join(',') : undefined;
	// Determines whether `FileItem` components should be rendered
	const shouldRenderFileItems =
		(multiple && Array.isArray(files)) || (!multiple && files);

	const labelText = multiple
		? 'Izaberite fajlove'
		: files
		? 'Promenite fajl'
		: 'Izaberite fajl';

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (sizeError) {
			timer = setTimeout(() => {
				setSizeError(null);
			}, 5000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [sizeError]);

	/**
	 * Handles key down events for a label element, specifically for triggering the file input dialog.
	 *
	 * @param {React.KeyboardEvent<HTMLLabelElement>} e The keyboard event
	 *
	 * @returns {void}
	 */
	const handleLabelKeyDown = (
		e: React.KeyboardEvent<HTMLLabelElement>
	): void => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault(); // Prevent scrolling when space is pressed
			inputRef.current?.click(); // Trigger file input click
		}
	};

	/**
	 * Handles file input changes and processes the selected files.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the file input.
	 *
	 * @returns {void}
	 *
	 * This function performs the following actions:
	 *
	 * 1. **Extract Files**: Converts the list of files from the input event into an array.
	 *
	 * 2. **Filter by Allowed Types**: If a list of allowed file types is provided (`allowedTypes`), it filters the files to include only those whose MIME types match the allowed types.
	 *
	 * 3. **Validate File Size**: If a maximum file size is specified (`maxSizeMB`), it converts this limit from megabytes to bytes and filters out any files that exceed this size. It also sets an error message if oversized files are detected.
	 *
	 * 4. **Ensure Files Array**: Ensures that `files` is treated as an array. If `files` is not already an array, it converts it into an array if it exists.
	 *
	 * 5. **Create a Set of Existing File Names**: Creates a Set of file names from the current list of files to facilitate duplicate detection.
	 *
	 * 6. **Filter Out Duplicates**: Filters out files from `newFiles` that are already present in the current list based on their file names.
	 *
	 * 7. **Update File List**: Updates the state with the valid and non-duplicate files. If `multiple` is true, it appends the new files to the existing list; otherwise, it replaces the current file with the new file.
	 *
	 * 8. **Reset File Input**: Resets the file input value to allow re-selection of the same file in subsequent changes.
	 */
	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		// Extract Files
		let newFiles = Array.from(event.target.files || []);

		// Filter by Allowed Types
		if (allowedTypes.length > 0) {
			newFiles = newFiles.filter((file) =>
				allowedTypes.includes(file.type as AllowedFileTypes)
			);
		}

		// Validate File Size
		if (maxSizeMB && maxSizeMB > 0) {
			const maxSizeBytes = maxSizeMB * 1024 * 1024;
			const oversizedFiles = newFiles.filter(
				(file) => file.size > maxSizeBytes
			);

			if (oversizedFiles.length > 0) {
				setSizeError(
					`Fajl ${oversizedFiles
						.map((file) => file.name)
						.join(', ')} ne sme biti veći od ${maxSizeMB}MB`
				);
				// Remove oversized files from newFiles array
				newFiles = newFiles.filter((file) => file.size <= maxSizeBytes);
			} else {
				setSizeError(null);
			}
		}

		// Ensure Files Array
		const currentFiles = Array.isArray(files)
			? files
			: files
			? [files]
			: [];

		// Create a Set of Existing File Names
		const existingFileNames = new Set(
			currentFiles.map((file) => file.name)
		);

		// Filter Out Duplicates
		newFiles = newFiles.filter((file) => !existingFileNames.has(file.name));

		// Update File List
		setValue(
			name,
			multiple ? [...currentFiles, ...newFiles] : newFiles[0] || null,
			{
				shouldValidate: true,
			}
		);

		// Reset File Input
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	/**
	 * Handles the deletion of a file from the list of files.
	 *
	 * @param {number} index - The index of the file to be deleted from the list.
	 *
	 * @returns {void}
	 *
	 * This function performs the following actions:
	 *
	 * 1. **Ensure Files Array**: Converts `files` into an array if it is not already an array. This ensures consistent handling of the files, whether `files` is a single file or an array of files.
	 *
	 * 2. **Filter Out File**: Creates a new array of files by filtering out the file at the specified index. This effectively removes the file from the list.
	 *
	 * 3. **Update Value**: Updates the state with the new list of files. If multiple files are allowed (`multiple` is `true`), it sets the state to the filtered array of files. If only one file is allowed (`multiple` is `false`), it sets the state to the first file in the filtered array or `null` if the array is empty.
	 *
	 * The function uses `shouldValidate` and `shouldDirty` options to trigger validation and mark the field as dirty.
	 */
	const handleFileDelete = (index: number): void => {
		// Ensure Files Array
		const currentFiles = Array.isArray(files) ? files : [files];

		// Filter Out File
		const newFiles = currentFiles.filter((_, i) => i !== index);

		// Update Value
		setValue(name, multiple ? newFiles : newFiles[0] || null, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	/**
	 * Conditionally renders `FileItem` components based on the state of `files` and the `multiple` flag.
	 *
	 * @returns {React.JSX.Element | React.JSX.Element[] | null}
	 * - a list of `FileItem` components (if `multiple` and `files` is an array),
	 * - a single `FileItem` component (if not `multiple` and `files` is a single file)
	 * - `null` if no files are present
	 */
	const renderFileItems = ():
		| React.JSX.Element
		| React.JSX.Element[]
		| null => {
		if (!showFiles) return null;
		if (multiple && Array.isArray(files)) {
			return files.map((file: File, index: number) => (
				<FileItem
					key={index}
					file={file}
					index={index}
					onDelete={handleFileDelete}
				/>
			));
		}

		if (!multiple && files instanceof File) {
			return (
				<FileItem file={files} index={0} onDelete={handleFileDelete} />
			);
		}

		return null;
	};

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				required: required
					? `Izaberite fajl${multiple ? '/fajlove' : ''}`
					: false,
			}}
			render={() => (
				<>
					<div className='flex flex-col gap-2'>
						<div className='inline-flex flex-wrap gap-2 items-center'>
							<label
								htmlFor={id}
								className='block mr-6 hover:cursor-pointer'>
								{label}
							</label>
							<div className='relative'>
								<input
									ref={inputRef}
									id={id}
									type='file'
									accept={acceptTypes}
									onChange={handleFileChange}
									className='hidden opacity-0'
									multiple={multiple}
								/>
								<label
									tabIndex={0}
									htmlFor={id}
									onKeyDown={handleLabelKeyDown}
									className='block px-8 py-2 outline-none rounded-lg border border-secondary bg-secondary-200 text-secondary font-bold cursor-pointer hover:bg-secondary-400 focus:bg-secondary-400 hover:text-white focus:text-white hover:border-secondary-400 focus:border-secondary-400'>
									{labelText}
								</label>
							</div>
						</div>
						{showFiles && shouldRenderFileItems ? (
							<ul className='flex flex-col gap-2'>
								{renderFileItems()}
							</ul>
						) : null}
					</div>
					{/* Error */}
					{(error || sizeError) && (
						<div className='text-danger'>
							{sizeError ? sizeError : error?.message}
						</div>
					)}
				</>
			)}
		/>
	);
};

export default FileInput;
