import { useCallback } from 'react';
import { useSubmit } from 'react-router-dom';
import { useAuth } from '../context/auth-context';

export const useLogout = () => {
	const { logoutAuthUser } = useAuth();
	const submit = useSubmit();

	//add from auth handle logout and expand
	const handleLogout = useCallback(() => {
		logoutAuthUser();
		submit(null, { action: '/logout', method: 'post', replace: true });
	}, [logoutAuthUser, submit]);

	return { handleLogout };
};
