
const RegionPreview = ({ region }: any) => {
	const regionItemClassName = 'h3 font-normal flex flex-col gap-1';

	return (
		<ul className='flex flex-col gap-3' id={region.region_id}>
			 <li className={regionItemClassName}>
        <b>Naziv:</b> {region.name}
      </li>
			
		</ul>
	);
};

export default RegionPreview
