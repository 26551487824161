import http from '../../../utils/http';

class AuthApi {
	// Authenticate user
	async authenticateUser(username: string, password: string) {
		return await http.post(`/login`, { username, password });
	}

	//Logout user
	async logoutUser() {
		return await http.post('/logout');
	}

	// Verify user
	async verifyCode(mfa: string) {
		return await http.post(`/mfa`, { mfa });
	}

	// Forget Password
	async forgetPassword(email: string) {
		return await http.post(`/users/forgotpasswordrequest`, {
			email,
		});
	}

	// Reset Password
	async resetPassword(
		token: undefined | string,
		password: string,
		repeatedPassword: string
	) {
		return await http.post('/users/resetpasswordwithtoken', {
			token,
			password,
			repeated_password: repeatedPassword,
		});
	}

	// Create Password
	async createPassword(
		token: undefined | string,
		password: string,
		repeatedPassword: string
	) {
		return await http.post('/users/createpasswordwithtoken', {
			token,
			password,
			repeated_password: repeatedPassword,
		});
	}

	// Refresh Token
	async refreshToken() {
		return await http.post('/refreshtoken');
	}
}

export default AuthApi;
