import { keepPreviousData, useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { handleErrors } from "../../../utils"
import NewsApi from "./news.api"
import { NewsPaginationInterface } from "./index.types"
import { AxiosResponse } from "axios";
import queryClient from "../../../config/tanstackQueryConfig";


export const QUERY_KEYS = {
	LATEST_NEWS: 'latest-news',
	NEWS: 'news',
};
const MINUTE: number = 1000 * 60

const api: NewsApi = new NewsApi()

class NewsStore {
    useGetLatestNews = () => {
        return  useQuery(getLatestNewsQueryOptions())
    }

    useFetchNews = (page:number, limit:number) => {
        return  useQuery(getNewsQueryOptions(page, limit))
    }
}

export default NewsStore;

/**
 * Function that returns query options for fetching news data.
 *
 * @returns {UseQueryOptions<NewsPaginationInterface, Error>}
 * Options for the `useQuery` hook to fetch news data.
 */
export const getNewsQueryOptions = (
	page: number,
	limit: number
): UseQueryOptions<NewsPaginationInterface, Error> => ({
	queryKey: [QUERY_KEYS.NEWS, { page, limit }],
	queryFn: async (): Promise<any> => {
        const response: AxiosResponse<any> = await api.fetchNews(
            page, limit
        );
        return response.data;
    },
	placeholderData: keepPreviousData,
});

export const getLatestNewsQueryOptions = (): UseQueryOptions<NewsPaginationInterface, Error> => ({
	queryKey: [QUERY_KEYS.LATEST_NEWS],
	queryFn: async (): Promise<any> => {
        const response: AxiosResponse<any> = await api.fetchLatestNews( );
        return response.data;
    },
	placeholderData: keepPreviousData,
});